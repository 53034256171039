import React from 'react';
import { graphql } from 'gatsby';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import { Fulla } from '../src/components/Fulla/Fulla';
import Hod from '../src/components/Hod/Hod';
import { Odin } from '../src/components/Odinv2/Odin';
import Hellheim from '../src/components/Hellheim/Hellheim';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

import './checkin.scss';

class Checkin extends React.Component {
  state = {};

  render() {
    let fullaData;
    let fullaDataMobile;

    const heimdallData = {
      name: this.props.data.allPromocionesTitleBlockCheckin.edges[0].node.title,
      subtitle: this.props.data.allPromocionesTitleBlockCheckin.edges[0].node.subTitle,
      image: {
        url: this.props.data.allPromocionesBlockImageInfoGeneralCheckin.edges[0].node.localImage
          .childImageSharp.fluid,
        alt: this.props.data.allPromocionesBlockImageInfoGeneralCheckin.edges[0].node.alt,
      },
      imageMobile: {
        alt: this.props.data.allPromocionesMobileTitleBlockCheckin.edges[0].node.alt,
        url: this.props.data.allPromocionesMobileTitleBlockCheckin.edges[0].node.localImage
          .childImageSharp.fluid,
      },
    };

    const odinData = {
      image: this.props.data.allPromocionesEventBlockCheckin.edges[0].node.localImage
        .childImageSharp.fluid,
      alt: this.props.data.allPromocionesEventBlockCheckin.edges[0].node.alt,
      title: this.props.data.allPromocionesEventBlockCheckin.edges[0].node.event_name,
      rssImg: true,
      buttons: {
        cta1: this.props.data.allPromocionesEventBlockCheckin.edges[0].node.call_to_action_path,
        ctaText1: this.props.data.allPromocionesEventBlockCheckin.edges[0].node.call_to_action_copy,
        size: 'alone',
        color1: 'orange',
      },
    };

    fullaData = {
      titleText: this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin.edges[0].node.title
        : null,
      fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin.edges[0].node.body_left
        : null,
      secondaryTitleText: this.props.data.allPromosFullaBlockCheckin.edges[0].node.title,
      secondaryfullaBody: this.props.data.allPromosFullaBlockCheckin.edges[0].node.body,
    };

    fullaDataMobile = {
      titleText: this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin.edges[0].node.title
        : null,
      titleType: 'H2',
      fullaBody: this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin
        ? this.props.data.allPromocionesContentMultiColumnBlockLeftCheckin.edges[0].node.body_left
        : null,
      secondaryTitleText: this.props.data.allPromosFullaBlockCheckin.edges[0].node.title,
      secondaryfullaBody: this.props.data.allPromosFullaBlockCheckin.edges[0].node.body,
    };

    const hellheimData = {
      title: this.props.data.allCheckinPreguntasFrecuentesTitle.edges[0].node.title,
      text: '',
      menus: this.props.data.allCheckinPreguntasFrecuentes.edges.map((item, index) => ({
        title: item.node.question,
        text: item.node.answer,
      })),
      titleType: 'h3',
    };

    const isMobileOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        <Fulla context="tienda-online" data={fullaDataMobile} />
      </>
    );
    const isDesktopOdin = (
      <>
        <Odin data={odinData} locale={this.props.pageContext.locale} />
        <Fulla context="tienda-online" data={fullaData} />
      </>
    );

    return (
      <Layout layoutFromOld 
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allPromocionesSeoDataCheckin.edges[0].node._0.title}
          description={this.props.data.allPromocionesSeoDataCheckin.edges[0].node._1.description}
          imageRich={
            this.props.data.allFichaPromosRichDataCheckin.edges[0].node.rich_markup.imgRich
          }
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="checkin-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                options={this.props.pageContext.options}
                title={this.props.data.allFichaPromosBreadCrumbBlockCheckin.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allFichaPromosRichDataCheckin.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={this.props.data.allFichaPromosRichDataCheckin.edges[0].node.imgRich}
              />
              <BlueArrow />
              <MediaServerRender predicted="mobile" hydrated>
                <MediaMatcher mobile={isMobileOdin} tablet={isMobileOdin} desktop={isDesktopOdin} />
              </MediaServerRender>

              <div className="freya-prom-container">
                <Hellheim data={hellheimData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Checkin.propTypes = {};

export default Checkin;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query allFichaPromocionQueryCheckin($locale: String!) {
    allPromocionesTitleBlockCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allPromocionesSeoDataCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allPromocionesBlockImageInfoGeneralCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesMobileTitleBlockCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allPromocionesEventBlockCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          call_to_action_copy
          call_to_action_path
          event_name
        }
      }
    }
    allPromocionesContentMultiColumnBlockLeftCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body_left
        }
      }
    }
    allPromosFullaBlockCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          title
          body
        }
      }
    }
    allFichaPromosBreadCrumbBlockCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allFichaPromosRichDataCheckin(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
            imgRich
            price
            date_valid_until
            date_valid_start
          }
        }
      }
    }
    allCheckinPreguntasFrecuentesTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allCheckinPreguntasFrecuentes(
      filter: { lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          question
          answer
        }
      }
    }
  }
`;
